<template>
  <button
    class="custom-butt"
    :style="{ color: $props.text_color ?? '' }"
    @click="handleClick"
    :class="`custom-butt-${type}st`"
  >
    <slot />
    {{ props.text }}
  </button>
</template>

<script setup lang="ts">
const props = defineProps({
  text: {
    type: String,
    required: true,
  },

  wh: {
    type: String,
    required: false,
    default: 'fit-content',
  },
  mwh: {
    type: String,
    required: false,
    default: 'unset',
  },
  type: {
    type: String,
    required: false,
    validator: function (value: string) {
      return ['1', '2', '3'].includes(value)
    },
    default: '1',
  },
  text_color: {
    type: String,
    required: false,
  },
  he: {
    type: String,
    required: false,
    default: '40px',
  },
  disabled: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['click'])

const handleClick = () => {
  emit('click')
}
</script>

<style scoped lang="scss">
.custom-butt {
  width: v-bind(wh);
  height: v-bind(he);
  max-width: v-bind(mwh);
  display: inline-flex;
  padding: 9px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: none;
  outline: none;
  white-space: nowrap;
  cursor: pointer;
  &-1st {
    background: #7444ab;
    color: #ffffff;
    &:hover {
      background: #643791;
    }
    &:active {
      background: #532878;
    }
  }
  &-2st {
    background: #f5f5fc;
    color: #7444ab;
    &:hover {
      background: #e9e9f3;
    }
    &:active {
      background: #dbdae5;
    }
  }
  &-3st {
    border: 1px solid var(--slate-200);
    background: var(--white, #fff);
    color: #7444ab;
    &:hover {
      background: #e9e9f3;
    }
    &:active {
      background: #dbdae5;
    }
  }
}
</style>
